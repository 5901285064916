import { createClient } from '@supabase/supabase-js'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const [resource, config] = args;
  const response = await originalFetch(resource, config);

  if (!response.ok && response.status === 401) {
    const { data } = await supabase.functions.invoke(
        'refresh-google-token',
        {
            body: {
                providerRefreshToken: localStorage.getItem('provider_refresh_token'),
            },
        }
    );

    localStorage.setItem('provider_token', data.access_token)

    config.headers.Authorization = `Bearer ${data.access_token}`

    console.log(config)
    
    return originalFetch(resource, config);
  }

  return response;
};