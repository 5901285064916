import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
// import { pinia } from '@/stores'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/AuthView.vue'),
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import('../views/StatsView.vue'),
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('../views/ServicesView.vue'),
    },
    {
      path: '/services/new',
      name: 'services-new',
      component: () => import('../views/ServicesNewView.vue'),
    },
    {
      path: '/services/:serviceId',
      name: 'services-id',
      component: () => import('../views/ServicesEditView.vue'),
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('../views/ClientsView.vue'),
    },
    {
      path: '/clients/new',
      name: 'clients-new',
      component: () => import('../views/ClientsNewView.vue'),
    },
    {
      path: '/clients/:clientId',
      name: 'clients-id',
      component: () => import('../views/ClientsDetailsView.vue'),
    },
    {
      path: '/clients/:clientId/appointments/:appointmentId',
      name: 'clients-id-appointments-edit',
      component: () => import('../views/AppointmentsEditView.vue'),
    },
    {
      path: '/clients/:clientId/appointments/new',
      name: 'clients-id-appointments-new',
      component: () => import('../views/AppointmentsNewView.vue'),
    },
    {
      path: '/clients/:clientId/edit',
      name: 'clients-edit',
      component: () => import('../views/ClientsEditView.vue'),
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('../views/CalendarView.vue'),
    },
    {
      path: '/appointments/new',
      name: 'appointments-new',
      component: () => import('../views/AppointmentsNewView.vue'),
    },
    {
      path: '/appointments/:appointmentId/edit',
      name: 'appointments-edit',
      component: () => import('../views/AppointmentsEditView.vue'),
    },
  ],
})

// const { supabase } = useAuthStore(pinia)
// supabase.auth.onAuthStateChange((event, session) => {
//   console.log(event, session)
//   if (event == 'SIGNED_OUT') {
//     console.log('go home', router.currentRoute.value.name)
//     return router.push({ name: 'home' })
//   }
//   // if (event == "SIGNED_IN") {
//   //   const routeName = router.currentRoute.value.name;
//   //   console.log("routeName", routeName);

//   //   if (routeName == "callback") {
//   //     setTimeout(() => {
//   //       return router.push({ name: "home" });
//   //     }, 0);
//   //   }
//   // }
// })

router.beforeEach(async (to, from) => {
  const { supabase } = useAuthStore()
  const response = await supabase.auth.getSession()

  if (!response.data.session && to.name !== 'home') {
    return {
      name: 'home',
    }
  }

  if (to.name === 'home' && response.data.session) {
    return {
      name: 'stats',
    }
  }

  if(!from.name && response.data.session) {
    localStorage.setItem('provider_token', response.data.session.provider_token)
    localStorage.setItem('provider_refresh_token', response.data.session.provider_refresh_token)
  }
})

export default router
